<template>
  <div class='d-none d-md-inline'>
    <!-- Home -->
    <v-row class='calendar mb-10 pa-10'>
      <v-spacer />
      <v-col cols='4'
             md='4'>
        <v-img :src='require(`@/assets/home_page/home_page_main.png`)' />
      </v-col>
      <v-col cols='4'
             md='4'
             class='d-flex align-center justify-center flex-column'>
        <div class='text-h4'>
          DOUCE PAPETERIE
        </div>
        <div class='text-h4 ma-2'>
          -
        </div>
        <div class='text-h5 text-center font-weight-light'
             style='font-weight: 1;'>
          Des illustrations poétiques, joyeuses et légères,
          sur des affiches, des faire-part, des cartes...
        </div>
        <div class='text-h5 mt-10'
             style='font-weight: 1;'>
          Tout ce qu’il faut pour rêver un peu.
        </div>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- CALENDAR -->
    <v-row class='mt-10'>
      <v-spacer />
      <!-- IMG -->
      <v-col class='d-flex align-center justify-center no-padding'
             cols='12'
             md='4'>
        <router-link to='/categories/33'
                     class='text-decoration-none no-padding'
                     style='width: 100%'>
          <v-img :src='require(`@/assets/home_page/bundles.jpg`)'
                 aspect-ratio='1'
                 contain />
        </router-link>
      </v-col>
      <!-- Texte -->
      <v-col class='d-flex align-center justify-center calendar-color'
             cols='12'
             md='4'>
        <v-row>
          <!-- Titre -->
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column'>
            <!-- <v-img :src='require(`@/assets/home_page/calendar/ico.png`)' width='100' />-->
            UN CADEAU UNIQUE!
          </v-col>

          <!-- Texte -->
          <v-col cols='12'
                 class='no-padding'>
            <p class='body-text text-center'>
              <span class='text-h5'>-</span>
              <br>
              Assortiments d’affiches et cartes
              <br>
              <span class='text-h5'>-</span>
              <br>
              Découvrez les 4 collections d’illustrations,
              <br>
              sélectionnées par thèmes,
              <br>
              à prix très réduits!
            </p>
          </v-col>

          <!-- BTN -->
          <v-col cols='12'
                 class='buttons-container mt-4'>
            <router-link to='/categories/33'
                         class='text-decoration-none d-flex justify-center'>
              <v-btn text
                     elevation='0'
                     color='black'
                     class='buttons'
                     outlined>
                Découvrir
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- ILLUSTRATIONS -->
    <v-row>
      <v-spacer />
      <!-- Texte -->
      <v-col class='d-flex align-center justify-center'
             cols='12'
             md='4'>
        <v-row>
          <!-- Titre -->
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column'>
            <v-img :src='require(`@/assets/home_page/branch_ico.png`)'
                   width='14%' />
            ILLUSTRATIONS
          </v-col>

          <!-- Texte -->
          <v-col cols='12'
                 class='no-padding'>
            <p class='body-text text-center'>
              <span class='text-h5'>-</span>
              <br>
              Découvrez les illustrations imprimées,
              <br>
              de la carte postale à l’affiche A3,
              <br>
              il y en a pour tous les goûts!
              <br>
              <br>
              <span>NOUVEAU!</span>
              <br>
              Découvrez les nouvelles options de personnalisation
            </p>
          </v-col>

          <!-- BTN -->
          <v-col cols='12'
                 class='buttons-container mt-4'>
            <router-link to='/categories/2'
                         class='text-decoration-none d-flex justify-center'>
              <v-btn text
                     elevation='0'
                     color='black'
                     class='buttons'
                     outlined>
                Découvrir
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <!-- IMG -->
      <v-col class='d-flex align-center justify-center no-padding'
             cols='12'
             md='4'>
        <router-link to='/categories/2'
                     class='text-decoration-none no-padding'
                     style='width: 100%'>
          <v-img :src='require(`@/assets/home_page/home_illustration_img.jpg`)'
                 aspect-ratio='1'
                 contain />
        </router-link>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- SUR MESURE -->
    <v-row>
      <v-spacer />
      <!-- IMG -->
      <v-col cols='12'
             md='4'
             class='no-padding'>
        <router-link to='/categories/7'
                     class='text-decoration-none no-padding'
                     style='width: 100%'>
          <v-img :src='require(`@/assets/home_page/home_made_img.jpg`)'
                 aspect-ratio='1'
                 contain />
        </router-link>
      </v-col>
      <!-- Texte -->
      <v-col cols='12'
             md='4'
             class='d-flex align-center illustration'>
        <v-row>
          <!-- Titre -->
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column'>
            <v-img :src='require(`@/assets/home_page/house_ico.png`)'
                   width='100' />
            <span class='white--text'>SUR MESURE</span>
          </v-col>

          <!-- Texte -->
          <v-col cols='12'
                 class='no-padding'>
            <p class='body-text text-center'>
              <span class='text-h5'>-</span>
              <br>
              Quel joli cadeau, à offrir ou à s’offrir,
              <br>
              une illustration faite rien que pour vous
            </p>
          </v-col>

          <!-- BTN -->
          <v-col cols='12'
                 class='buttons-container mt-4'>
            <router-link to='/categories/7'
                         class='text-decoration-none d-flex justify-center'>
              <v-btn text
                     elevation='0'
                     color='white'
                     class='buttons'
                     outlined>
                En savoir plus
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- CREA’FRICHE -->
    <v-row class='mb-10'>
      <v-spacer />
      <!-- Texte -->
      <v-col cols='12'
             md='4'
             class='d-flex align-center atelier'>
        <v-row>
          <!-- Titre -->
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column'>
            <span class='black--text'>CREA’FRICHE</span>
          </v-col>

          <!-- Texte -->
          <v-col cols='12'
                 class='no-padding'>
            <p class='body-text text-center'>
              <span class='text-h5'>-</span>
              <br>
              Réservez ici votre atelier d’initiation à l'aquarelle,
              <br>
              le mercredi 14 Mai 2025
              <br>
              à La Friche / Saint-André-lez-Lille
            </p>
          </v-col>

          <!-- BTN -->
          <v-col cols='12'
                 class='buttons-container mt-4'>
            <router-link to='/products/748'
                         class='text-decoration-none d-flex justify-center'>
              <v-btn text
                     elevation='0'
                     color='black'
                     class='buttons'
                     outlined>
                En savoir plus
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <!-- IMG -->
      <v-col cols='12'
             md='4'
             class='no-padding'>
        <router-link to='/products/748'
                     class='text-decoration-none no-padding'
                     style='width: 100%'>
          <v-img :src='require(`@/assets/home_page/creafriche.jpg`)'
                 aspect-ratio='1'
                 contain />
        </router-link>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- Carousel -->
    <v-row class='mt-10 mb-10'>
      <v-spacer />
      <v-col cols='10'>
        <v-slide-group show-arrows>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_1/1.jpg`)'
                   max-width='350'
                   max-height='350'
                   contain />
          </v-slide-item>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_1/2.jpg`)'
                   max-width='350'
                   max-height='350'
                   contain />
          </v-slide-item>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_1/3.jpg`)'
                   max-width='350'
                   max-height='350'
                   contain />
          </v-slide-item>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_1/4.jpg`)'
                   max-width='350'
                   max-height='350'
                   contain />
          </v-slide-item>
        </v-slide-group>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- Carte Naissance -->
    <v-row class='birth mt-10 mb-10'
           style='padding: 40px 0 !important'>
      <v-spacer />
      <!-- Texte -->
      <v-col cols='12'
             md='4'
             class='white'>
        <v-row>
          <!-- Titre -->
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column mt-10'>
            <v-img :src='require(`@/assets/home_page/card_ico.png`)'
                   width='100' />
            <span>NAISSANCES</span>
          </v-col>

          <!-- Texte -->
          <v-col cols='12'>
            <p class='text-center body-text'>
              <span class='text-h5'>-</span>
              <br>
              <br>
              Faire-part,
              <br>
              cartes étapes,
              <br>
              cartes première année,
              <br>
              affiches personnalisables...
              <br>
              <br>
              Bienvenue dans un univers
              <br>
              de douceur et de poésie
            </p>
          </v-col>

          <!-- BTN -->
          <v-col cols='12'
                 class='d-flex justify-center mt-2 mb-10'>
            <router-link to='/categories/22'
                         class='text-decoration-none'>
              <v-btn text
                     elevation='0'
                     outlined>
                découvrir
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <!-- IMG -->
      <v-col cols='12'
             md='4'>
        <v-img :src='require(`@/assets/home_page/birth_fp.jpg`)' />
      </v-col>
      <v-spacer />
    </v-row>

    <!-- Carousel -->
    <v-row class='mt-10 mb-10'>
      <v-spacer />
      <v-col cols='10'>
        <v-slide-group show-arrows>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_2/1.jpg`)'
                   max-width='350'
                   max-height='350'
                   contain />
          </v-slide-item>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_2/2.jpg`)'
                   max-width='350'
                   max-height='350'
                   contain />
          </v-slide-item>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_2/3.jpg`)'
                   max-width='350'
                   max-height='350'
                   contain />
          </v-slide-item>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_2/4.jpg`)'
                   max-width='350'
                   max-height='350'
                   contain />
          </v-slide-item>
        </v-slide-group>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- ABOUT ME -->
    <v-row class='mt-10 mb-10'
           style='padding: 40px 0 !important'>
      <v-spacer />
      <!-- IMG -->
      <v-col cols='12'
             md='4'>
        <v-img :src='require(`@/assets/home_page/about_me.jpg`)'
               max-width='600' />
      </v-col>
      <!-- Texte -->
      <v-col cols='12'
             md='4'
             class='white'>
        <v-row>
          <!-- Titre -->
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column'>
            <v-img :src='require(`@/assets/home_page/pen_ico.png`)'
                   width='100' />
            <span>HELLO</span>
          </v-col>

          <!-- Texte -->
          <v-col cols='12'>
            <p class='text-center body-text'>
              <span class='text-h5'>-</span>
              <br>
              “100% PERSONNALISABLE!”
              <br>
              <br>
              Je suis Marie, graphiste lilloise,
              <br>
              maman passionnée par l’illustration,
              <br>
              diplômée en arts appliqués et
              <br>
              communication depuiiis......bref :)
              <br>
              Je me suis lancée dans la création de faire-part
              <br>
              en 2016 et depuis mon travail s’est élargi
              <br>
              en déclinant mes illustrations sur plusieurs supports
              <br>
              (affiches, livres, packaging...)
              <br>
              <br>
              En étant sur ce site, vous êtes en contact direct
              <br>
              avec moi, ce qui facilite les échanges
              <br>
              et la personnalisation des créations.
              <br>
              N’hésitez pas à me poser toutes vos questions!
            </p>
          </v-col>

          <!-- BTN -->
          <v-col cols='12'
                 class='d-flex justify-center mt-2'>
            <router-link to='/infos/contact'
                         class='text-decoration-none'>
              <v-btn text
                     elevation='0'
                     outlined>
                Contact
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'StandardHome',
    data: () => ({
      calendarId: process.env.VUE_APP_CALENDAR_ID
    }),
    props: {
      calendar: { type: Object, default: () => {} },
      carouselImages: { type: Array, default: () => [] }
    }
  }
</script>

<style lang='scss' scoped>
  .buttons {
    font-weight: 1000;
    @media all and (min-width: 2081px) {
      font-size: 1.5rem !important;
    }
  }

  .calendar {
    background-color: #f3ebe2;
    div {
      padding: 0 !important;
    }
  }

  .calendar-title {
    color: #211f1f;
    //text-align: center;
    font-size: 2rem;
    font-weight: 1;
    @media all and (min-width: 2081px) {
      font-size: 3.5rem !important;
    }
  }

  .calendar-text {
    font-size: 0.8rem !important;
    color: #211f1f;
    font-weight: 400;
    line-height: 1.35rem;
    letter-spacing: .0333333333em !important;
    @media all and (min-width: 2081px) {
      font-size: 2rem !important;
      line-height: 2rem;
    }
  }

  .illustration {
    background-color: #b58a64;
    color: white;
    //div {
    //  padding: 0 !important;
    //  @media all and (max-width: 735px) {
    //    padding: 50px !important;
    //  }
    //}
  }

  .atelier {
    background-color: #f0cfae;
    color: black;
  }

  .calendar-color {
    background-color: #f2c8c9;
    color: black;
  }

  .illustration-title {
    color: white;
    font-weight: 1000;
    font-size: 1.5rem;
    padding-left: 5rem;
    @media all and (min-width: 2081px) {
      font-size: 3.5rem !important;
    }
  }

  .illustration-text {
    font-size: 0.8rem !important;
    color: white;
    font-weight: 400;
    line-height: 1.35rem;
    letter-spacing: .0333333333em !important;
    @media all and (min-width: 2081px) {
      font-size: 2rem !important;
      line-height: 2rem;
    }
  }

  .img-resize {
    @media all and (max-width: 1024px) {
      width: 7% !important;
    }

    @media all and (min-width: 1025px) and (max-width: 1400px) {
      width: 9% !important;
    }

    @media all and (min-width: 1401px) and (max-width: 1700px) {
      width: 12% !important;
    }

    @media all and (min-width: 1701px) and (max-width: 1920px) {
      width: 15% !important;
    }

    @media all and (min-width: 1921px) and (max-width: 2080px) {
      width: 18% !important;
    }

    @media all and (min-width: 2081px) {
      width: 25% !important;
    }
  }

  .no-padding {
    padding: 0 !important;
  }

  .birth {
    background-color: #e4d0c3;
    div {
      padding: 0 !important;
    }
  }

  .body-title {
    color: #211f1f;
    font-size: 2rem;
    font-weight: 1;
    @media all and (min-width: 2081px) {
      font-size: 3.5rem !important;
    }
  }

  .body-text {
    font-size: 1.1rem !important;
    font-weight: 100;
    @media all and (min-width: 2081px) {
      font-size: 1.2rem !important;
    }
  }
</style>
